<template>
  <md-card
    class="section-description-preview"
    :class="{
      'section-description-preview--bottom-margin': bottom_margin,
      'section-description-preview--dense': dense,
    }"
    >
    <md-card-expand>
      <md-card-actions md-alignment="space-between">
        <slot v-if="$slots.title" name="title" />
        <div v-else class="md-body-1">{{ $translate("section_description_title") }}</div>

        <md-card-expand-trigger>
          <md-button class="md-icon-button" :class="{ 'md-dense': dense }">
            <md-icon>keyboard_arrow_down</md-icon>
          </md-button>
        </md-card-expand-trigger>
      </md-card-actions>

      <md-card-expand-content>
        <md-card-content>
          <slot />
        </md-card-content>
      </md-card-expand-content>
    </md-card-expand>
  </md-card>
</template>

<script>
export default {
  props: {
    bottom_margin: {
      required: false,
      type: Boolean,
      default: true,
    },
    dense: {
      required: false,
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style lang="scss">
  @use "../../../../styles/_admin-constants" as *;

  .section-description-preview {
    margin: 0 !important;
    box-shadow: none !important;
    border-bottom: 1px solid $material-grey--light;
    max-width: 500px;

    &--bottom-margin {
      margin: 0 0 20px !important;
    }

    &--dense {
      max-width: 400px;
    }

    .md-card-actions {
      padding: 0 0 $half-default-size;
    }

    .md-card-content {
      padding: 0 0 $half-default-size;
    }

    .md-body-1 {
      margin: 0;
    }

    .md-card-content:last-of-type {
      padding-bottom: $half-default-size;
    }
  }
</style>
