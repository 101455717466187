import { translate } from "../../../Shared/translator";

export const MISSING_NAME = async (entity, id, value) => ({
  type: "entity-name",
  message: await translate("errors.types.missing_name", { entity, value }),
  id
})

export const MISSING_SELECTED_ITEMS = async (entity, id) => ({
  type: "selected-items",
  message: await translate("errors.types.selected_items", { entity }),
  id
})

export const DUPLICATE_ENTITY_TITLE = async (entity, id) => ({
  type: "duplicate-entity-title",
  message: await translate("errors.types.duplicate_title", { entity }),
  id
})

export const DUPLICATE_VARIANTS = async id => ({
  type: "duplicate-variant",
  message: await translate("errors.product.duplicate_variants"),
  id
})

export const DUPLICATE_SHIPPING_OPTIONS = async (order, id) => ({
  type: "duplicate-variant",
  message: await translate("errors.configuration.duplicate_shipping_option", { order }),
  id
})

export const EMPTY_SHIPPING_OPTIONS = async id => ({
  type: "empty-shipping-options-translations",
  message: await translate("errors.configuration.empty_shipping_option"),
  id
})

export const MISSING_TRANSLATIONS_FOR_SHIPPING_OPTIONS = async id => ({
  type: "missing-shipping-options-translations",
  message: await translate("errors.configuration.missing_shipping_option_translations"),
  id
})

export const MISSING_VARIANT = async id => ({
  type: "missing-variant",
  message: await translate("errors.product.missing_variant"),
  id
})

export const MISSING_PRICE = async (entity, entity_name, id) => ({
  type: "missing-price",
  message: await translate("errors.product.missing_price", { entity, entity_name }),
  id
})

export const MISSING_DIMENSION = async id => ({
  type: "missing-dimension",
  message: await translate("errors.product.missing_dimension"),
  id
})

export const MISSING_CATEGORIZATION = async id => ({
  type: "missing-categorization",
  message: await translate("errors.product.missing_categorization"),
  id
})

export const MISSING_PROJECT_COUNTRY = async id => ({
  type: "missing-project-country",
  message: await translate("errors.product.missing_country"),
  id
})

export const MISSING_VARIANT_VALUE_FOR_TRANSLATION = async id => ({
  type: "missing-value-for-translation",
  message: await translate("errors.product.missing_value_for_translation"),
  id
})

export const MISSING_CROSS_CURRENCY = async id => ({
  type: "missing-cross-currency",
  message: await translate("errors.product.missing_cross_currency"),
  id
})

export const DEFAULT_ERROR = (id, message) => ({
  type: "default-error",
  message,
  id
})
